import "./styles.css";
import usePartySocket from "partysocket/react";
import { createRoot } from "react-dom/client";
import { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import chroma from "chroma-js";

function App() {
  const [color, setColor] = useState("#FFFFFF"); // color from server
  const [userColor, setUserColor] = useState("#E7E8EF"); // user selected color
  const [score, setScore] = useState(null);
  const [gameCountdown, setGameCountdown] = useState(20000); // Initialize game countdown
  const [leaderboardCountdown, setLeaderboardCountdown] = useState(0); // Initialize leaderboard countdown

  const [hasSubmitted, setHasSubmitted] = useState(false); // track if user has submitted
  const [leaderboard, setLeaderboard] = useState([]);
  const [playerName, setPlayerName] = useState("");
  const [count, setCount] = useState(0);
  const [accuracy, setAccuracy] = useState(null); // Accuracy based on color difference
  const [timeTaken, setTimeTaken] = useState(null); // Time taken to submit color

    useEffect(() => {
        // Check localStorage for a stored name
        const storedName = localStorage.getItem("playerName");
        if (storedName) {
          setPlayerName(storedName);
        }
      }, []);

  const ws = usePartySocket({
    room: "example-room",
    onMessage(evt) {
      const message = evt.data;
      if (message.startsWith("color: ")) {
        setColor(message.split("color: ")[1]);
        setScore(null);
        setHasSubmitted(false);
        // No need to manually set countdowns here, server manages them
      } else if (message.startsWith("gameCountdown: ")) {
        setGameCountdown(parseInt(message.split("gameCountdown: ")[1], 10));
      } else if (message.startsWith("leaderboardCountdown: ")) {
        setLeaderboardCountdown(parseInt(message.split("leaderboardCountdown: ")[1], 10));
      } else if (message.startsWith("leaderboard: ")) {
        setLeaderboard(JSON.parse(message.split("leaderboard: ")[1]));
      }
    },
  });
  const handleColorChange = (color, event) => {
    setUserColor(color.hex);
  };


    const durableObjectName = 'COUNTER_COLORGAMES'; // Replace with the actual name of your Durable Object
    const fetchCount = async () => {
        try {
            const response = await fetch(`https://ts-gen-count.adam-f8f.workers.dev/?name=${durableObjectName}`);
            const data = await response.text();
            setCount(data);
        } catch (error) {
            console.error('Error fetching count:', error);
        }
    };

    const handleIncrement = async () => {
        try {
            await fetch(`https://ts-gen-count.adam-f8f.workers.dev/increment?name=${durableObjectName}`, {
                method: 'POST',
            });
            fetchCount(); // Update count after increment
        } catch (error) {
            console.error('Error incrementing count:', error);
        }
    };


const handleSubmit = () => {
  if (!hasSubmitted && gameCountdown > 0) {
    let name = playerName;
    if (!name) {
      name = prompt("Please enter your name:");
      if (name) {
        setPlayerName(name);
        localStorage.setItem("playerName", name);
      } else {
        return; // Exit if no name is entered
      }
    }

    const deltaE = chroma.deltaE(color, userColor);
    const timeFactor = gameCountdown / 20000; // Normalize time factor (0 to 1)

    // Calculate accuracy and time taken
    const accuracy = 100 - deltaE; // Assuming 100 - deltaE represents accuracy
    const timeTaken = (20000 - gameCountdown) / 1000; // Time taken in seconds

    // Adjust these weights as necessary to balance difficulty
    const accuracyWeight = 75; // Weight for color accuracy
    const timeWeight = 25;    // Weight for time factor
    const accuracyScore = accuracy * (accuracyWeight / 100);
    const timeScore = timeFactor * (timeWeight / 100);
    const totalScore = Math.max(0, Math.min(100, accuracyScore + timeScore)); // Clamp score between 0 and 100

    setScore(totalScore);
    setAccuracy(accuracy); // Set accuracy
    setTimeTaken(timeTaken); // Set time taken
    setHasSubmitted(true);

    ws.send(`score: ${JSON.stringify({ name: name, score: totalScore })}`);
  }
};

  const formattedGameCountdown = (gameCountdown / 1000).toFixed(2);
  const formattedLeaderboardCountdown = (leaderboardCountdown / 1000).toFixed(0);

  return (
    <div style={{ backgroundColor: userColor, minHeight: '100dvh', color: chroma.contrast(userColor, 'white') > 4.5? 'white' : 'black' }}>
      <div style={{ width: "100%", height: "192px", backgroundColor: color, color: chroma.contrast(color, 'white') > 4 ? 'white' : 'black' }}>
<div>
  {gameCountdown > 0 ? 
        <div style={{textAlign: 'center', paddingTop: '8px'}}><time style={{ fontSize: '12px', color: 'white', fontFamily: 'monospace', background: 'rgba(0,0,0,.7)', borderRadius: '9999px', display: 'inline-block', padding: '4px 12px'}}>{formattedGameCountdown}</time></div> : 
        (leaderboardCountdown > 0 ? <div style={{textAlign: 'center', paddingTop: '8px'}}><time style={{ fontSize: '12px', color: 'white', fontFamily: 'monospace', background: 'rgba(0,0,0,.7)', borderRadius: '9999px', display: 'inline-block', padding: '4px 12px'}}>Next game in {formattedLeaderboardCountdown}</time></div> : null)}

{score !== null && (
        <div>
          <div>Your Score: {score.toFixed(2)}</div>
          <div>Accuracy: {accuracy.toFixed(2)}</div>
          <div>Time Taken: {timeTaken.toFixed(2)} seconds</div>
        </div>
      )}
</div>
     </div>
      <div
        style={{
          width: "100%",
          height: "192px",
          backgroundColor: userColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: '480px' }}>
            <ChromePicker disableAlpha={true} width='300px' color={userColor} onChange={handleColorChange} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '48px' }}>
          <button className='animated-button' onClick={handleSubmit} disabled={hasSubmitted}>
<span></span>
<span></span>
<span></span>
<span></span>
          Submit Color</button>
      </div>
 <section>
      <h4 style={{ textAlign: 'center', marginTop: '32px', fontSize: '12px' }}>Leaderboard</h4>
      <ol style={{ padding: 0, maxWidth: '320px', listStyleType: 'none', margin: '0 auto' }}>
        {leaderboard.map((entry, index) => (
          <li key={index}
          style={{
                display: 'flex',
                fontSize: '12px',
                gap: '16px',
              }}
          >
              <strong>{index+1}</strong>
              <strong>{entry.name}</strong>
              <code style={{ marginLeft: 'auto' }}>{entry.score.toFixed(3)}</code>
          </li>
        ))}
      </ol>
  </section>
  
    </div>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
